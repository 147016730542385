<template>
  <b-row class="filters-container">
    <b-col cols="6">
      <label for="patient">Paciente</label>
      <div class="search-container">
        <Search class="icon" />
        <b-form-input
          type="text"
          v-model="filters.patient"
          debounce="500"
          placeholder="Buscar paciente"
        />
      </div>
    </b-col>

    <b-col cols="3">
      <label for="guide-number">Nº da guia</label>
      <b-form-input
        id="guideNumber"
        type="text"
        v-model="filters.guideNumber"
        placeholder="Pesquisar"
        debounce="500"
      />
    </b-col>

    <b-col cols="3">
      <label for="">Situação</label>
      <div class="multiselect">
        <v-multiselect
          :value="status"
          :options="status"
          v-on:onChange="changeSelectedStatus"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Search from '@/assets/icons/search.svg'
import CheckboxSelect from '../../../CheckboxSelect.vue'

export default {
  components: {
    Search,
    'v-multiselect': CheckboxSelect
  },
  data() {
    return {
      filters: {
        patient: null,
        guideNumber: null,
        guideType: null,
        status: []
      },
      status: [
        'Todos',
        'Pago',
        'Pago Parcialmente',
        'Glosada',
        'Em recurso',
        'Enviado',
        'Aguardando pagamento'
      ]
    }
  },
  methods: {
    changeSelectedStatus(newValue) {
      this.filters.status = newValue
    }
  },
  watch: {
    filters: {
      handler(newValue) {
        this.$emit('change', newValue)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.filters-container {
  margin: 20px 0;

  label {
    font-weight: 700;
  }

  .search-container {
    display: flex;
    flex-direction: row;
    position: relative;

    .icon {
      position: absolute;
      width: 24px;
      height: 24px;
      stroke: var(--neutral-500);
      height: 100%;
      margin-left: 10px;
    }

    input {
      padding-left: 40px !important;
    }
  }
}
</style>